import React from 'react';
import { Helmet } from "react-helmet"
import Layout from "../Layout"
import SkillsList from "../components/Resume/SkillsList";
import NotableProjects from "../components/Resume/NotableProjects";
import { PageHeader } from "../components/Content";

const Resume = ({location, ...props}) => {
    const title = `These are my creds, because it's expected. But, I'm not.`;

    return (
        <Layout location={location}>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta name="description" content="Overview of Michael Lisboa's experience and capabilities." />
                <meta property="og:description" content="Overview of Michael Lisboa's experience and capabilities." />
                <meta name="twitter:description" content="Overview of Michael Lisboa's experience and capabilities." />
            </Helmet>
            <PageHeader
                theme="#ef5350"
                title={`My creds.\nBecause it's\nexpected.`}
                subtitle="But, I'm not."
            />
            <section>
                <div className="uk-container uk-container-expand uk-flex uk-flex-center">
                    <div className="uk-padding-small uk-width-2-3@m">
                        <div className="uk-grid-small uk-margin-medium-top uk-margin-remove-bottom" data-uk-grid>
                            <NotableProjects />
                            <SkillsList />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Resume;
