import React from "react";
import * as style from './Resume.module.css';

const SkillsList = props =>
    <aside className={`${style.skillsList} uk-width-1-3@m uk-flex-last`}>
        <p className="uk-h5 uk-margin-bottom">&mdash; Agency Experience</p>
        <div className="uk-margin-medium-top">
            <h6 className="uk-text-uppercase uk-margin-small-bottom uk-margin-remove-top">
                <span className="uk-text-bold">Consultant</span><br />
                <small className="uk-text-muted">
                    Professional Silver Bullet<br />May 2016&mdash;Present
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">Havas Worldwide</span><br />
                <small className="uk-text-muted">
                    Regional Experience Director, SEA<br />May 2015&mdash;Mar 2016
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">Publicis <span style={{fontWeight: "100", textTransform: "lowercase"}} className="uk-text-muted">(Contract)</span></span><br />
                <small className="uk-text-muted">
                    Director of User Experience, Singapore<br />Feb 2015&mdash;May 2015
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">Dentsu</span><br />
                <small className="uk-text-muted">
                    Regional Creative Director, SEA<br />Apr 2014&mdash;Feb 2015
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">Twentysix (MSQ Partners)</span><br />
                <small className="uk-text-muted">
                    Regional Creative Director, APAC<br />Sept 2012&mdash;Mar 2014
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">The Gate Worldwide (MSQ Partners)</span><br />
                <small className="uk-text-muted">
                    Creative Director, Singapore<br />Sept 2012&mdash;Mar 2014
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">Eight Inc.</span><br />
                <small className="uk-text-muted">
                    Director of Communications, APAC<br />JAN 2011&mdash;Jan 2012
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">Frog Design <span style={{fontWeight: "100", textTransform: "lowercase"}} className="uk-text-muted">(Contract)</span></span><br />
                <small className="uk-text-muted">
                    Lead Experience Designer, San Francisco<br />Dec 2005&mdash;Dec 2006
                </small>
            </h6>

            <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
                <span className="uk-text-bold">Kizmo</span><br />
                <small className="uk-text-muted">
                    Chief TechnoCreativeologist<br />Feb 2005&mdash;Present
                </small>
            </h6>
        </div>

        <p className="uk-h5 uk-margin-bottom">&mdash; Patents</p>
        <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
            <span className="uk-text-bold">US Patent 12/633,371 (Sole author)</span><br />
            <small className="uk-text-muted">
                Event Associating Software Application for Accessing Digital Media
            </small>
        </h6>
        <h6 className="uk-text-uppercase uk-margin-remove-top uk-margin-small-bottom">
            <span className="uk-text-bold">US Patent 12/556,597 (Sole author)</span><br />
            <small className="uk-text-muted">
                Simple Mobile Registration
            </small>
        </h6>

        <p className="uk-h5">&mdash; Capabilities</p>
        <h6 className={`${style.sidebarTitle} uk-text-uppercase uk-margin-small-top uk-margin-remove-bottom uk-padding-remove`}>
            Creative Strategy</h6>
        <div>
            <span className="uk-text-small">Digital Marketing</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Content Marketing</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Copywriting</span>
        </div>

        <h6 className={`${style.sidebarTitle} uk-text-uppercase uk-margin-small-top uk-margin-remove-bottom uk-padding-remove`}>
            User Experience</h6>
        <div>
            <span className={`uk-text-small`}>User Personas and Mental Models</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Customer Journey Mapping</span>
        </div>
        <div>
            <span className={`uk-text-small`}>UX Research (qualitative and quantitative)</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Usability Testing</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Wireframes and Rapid Prototyping</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Graphic and UI Design</span>
        </div>

        <h6 className={`${style.sidebarTitle} uk-text-uppercase uk-margin-small-top uk-margin-remove-bottom uk-padding-remove`}>
            Full-Stack Programming</h6>
        <div>
            <span className={`uk-text-small`}>Python, Django, Flask</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Django REST Framework</span>
        </div>
        <div>
            <span className={`uk-text-small`}>FastAPI</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Ruby, Ruby on Rails</span>
        </div>
        <div>
            <span className={`uk-text-small`}>JAMStack</span>
        </div>
        <div>
            <span className={`uk-text-small`}>JavaScript ES6+</span>
        </div>
        <div>
            <span className={`uk-text-small`}>React</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Contentful</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Prismic.io</span>
        </div>
        <div>
            <span className={`uk-text-small`}>GraphQL</span>
        </div>

        <h6 className={`${style.sidebarTitle} uk-text-uppercase uk-margin-small-top uk-margin-remove-bottom uk-padding-remove`}>
            Software</h6>
        <div>
            <span className={`uk-text-small`}>Adobe Photoshop, Illustrator, etc.</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Sketch, Figma, and InVision</span>
        </div>
        <div>
            <span className={`uk-text-small`}>OmniGraffle, Balsamic, and Axure</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Final Cut Pro, Premier, and After Effects</span>
        </div>

        <h6 className={`${style.sidebarTitle} uk-text-uppercase uk-margin-small-top uk-margin-remove-bottom uk-padding-remove`}>
            Backend Tech</h6>
        <div>
            <span className={`uk-text-small`}>Google Cloud</span>
        </div>
        <div>
            <span className={`uk-text-small`}>AWS (Amazon Web Services)</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Microsoft Azure</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Heroku</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Pretty much any cloud services</span>
        </div>
        <div>
            <span className={`uk-text-small`}>Postgresql and MySQL</span>
        </div>
    </aside>

export default SkillsList
