import React from "react";
import * as style from './Resume.module.css';

const NotableProjects = props =>
    <article className={`${style.workExperience} uk-article uk-width-expand@m uk-flex-first`}>
        <p className="uk-h5 uk-margin-small">&mdash; Notable Projects</p>

        <div className={style.experienceCopy}>
            <p className="uk-h4 uk-heading-line uk-margin-medium-top
                uk-text-uppercase uk-margin-remove-bottom">
                <span>Saudi Arabia, Ministry of Housing</span>
            </p>
            <small>Consultant</small>
            <p className={`${style.experienceCopyMeta} uk-article-meta`}>
                Product Design &bull; UX/UI Design &bull; Prototyping
            </p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                The Kingdom of Saudi Arabia is on a mission to transform fragmented government systems
                into a one-stop digital solution for property owners, buyers, lessees, and brokers, as well as
                the Ministries of Justice, Finance, and other government agencies.</p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                Working with the Saudi Ministry of Housing, I focused on designing an intuitive solution, rooted in
                research, that will transform their current systems into a forward-thinking and future-proof web
                application for all target audiences.
            </p>
            <ul className="uk-list uk-list-bullet">
                <li>Designed and led workshops with the client, on-site in Riyadh, to identify audiences, personas, goals, and journeys.</li>
                <li>Worked with the client’s product team to clarify business processes, requirements, and dependencies on Saudi government
                    sources of data, as well as requirements and processes for property brokers throughout the country</li>
                <li>Performed research on Saudi property contract law, and property management systems in Saudi Arabia, focused on predicting
                    technology and systems growth over the next 10 years.</li>
                <li>Produced research documentation, wireframes, and prototypes to be vetted by the client as well as to provide clear
                    product design goals for the development teams.</li>
            </ul>
        </div>

        <div>
            <p className="uk-h4 uk-heading-line
                uk-text-uppercase uk-margin-remove-bottom">
                <span>Pixt</span>
            </p>
            <p className={`${style.experienceCopyMeta} uk-article-meta`}>
                Start-up &bull; Artificial Intelligence &bull; Deep-tech
            </p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                Businesses are spending $Billions trying to understand their customers, but they're blind to trillions
                of real-time visual conversations. Pixt indexes the visual web to make sense, and generate quantified
                data, of the billions of images and videos shared on the web every day.
            </p>
            <ul className="uk-list uk-list-bullet">
                <li>Led fundraising and business development efforts in many, many pitches.</li>
                <li>Raised USD$120,000 pre-seed and USD$100,000 from Google Cloud for Startups.</li>
                <li>Performed market and product fit analysis to identify business verticals to target at launch.</li>
                <li>Developed a machine learning-powered platform, data models and subsequent deployment on
                high-availability Google Cloud infrastructure.</li>
                <li>Executed all UX and UI Design for desktop, mobile, PWA and bot interfaces.</li>
                <li>Programmed the platform and micro-services in Python, Django, and React.</li>
            </ul>
        </div>

        <div className={style.experienceCopy}>
            <p className="uk-h4 uk-heading-line
                uk-text-uppercase uk-margin-remove-bottom">
                <span>Pepsi</span>
            </p>
            <small>Consultant</small>
            <p className={`${style.experienceCopyMeta} uk-article-meta`}>
                Creative Direction &amp; Strategy &bull; Brand Experience
            </p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                Conceived, executed and delivered one of the first digital integrated brand campaigns in the world,
                curating and aggregating content through Pepsi's iconic #refresheverything brand message.</p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                Leveraging on Pepsi generated and paid content, alongside user-generated content, this campaign set
                a standard for Pepsi's digital campaigns ever since.
            </p>
            <ul className="uk-list uk-list-bullet">
                <li>Developed a digital strategy and creative solution around Pepsi’s “rethink the old, refresh
                with the new” campaign objectives.</li>
                <li>Created a digital strategy and engaged users to interact with, and create, fresh content
                provided by Pepsi’s content curation team.</li>
                <li>Led the ideation and design of a crowdsourced content strategy that included free music downloads,
                Facebook integration, and interaction with popular sites like Twitter, YouTube, Flickr, and more.</li>
            </ul>
        </div>

        <div>
            <p className="uk-h4 uk-heading-line
                uk-text-uppercase uk-margin-remove-bottom">
                <span>Citibank</span>
            </p>
            <small>Eight Inc.</small>
            <p className={`${style.experienceCopyMeta} uk-article-meta`}>
                Creative &amp; Account Direction &bull; UI/UX Design
            </p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                Customers were detached from their banks and their money. Our focus was to evaluate
                customer needs and redefine the banking experience. We used a combination of
                technology, architecture and design, supplemented with new retail channels that
                connected with customers and gave them a much better way to do their banking.
            </p>
            <ul className="uk-list uk-list-bullet">
                <li>Spearheaded rebranding and remodelling of the consumer banking environmental
                    and digital experience of Citibank in Asia.</li>
                <li>Reformulated retail banking strategy by creating a new consumer banking
                    experience with new, simpler tool sets, all founded on intuitive user experience
                    strategy and design.</li>
                <li>Led Citibank’s shift into the future of engagement banking, rethinking of its
                    retail environments, behaviors, technology, and communications&mdash;every aspect
                    of the brand and how the customer experiences it.</li>
            </ul>
        </div>

        <div>
            <p className="uk-h4 uk-heading-line
                uk-text-uppercase uk-margin-remove-bottom">
                <span>Microsoft</span>
            </p>
            <small>Contract</small>
            <p className={`${style.experienceCopyMeta} uk-article-meta`}>
                UI/UX Design &bull; Prototyping &bull; Advertising
            </p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                With the Windows Vista launch looming&mdash;one of the largest global
                product launches in history&mdash;I worked closely with Microsoft's
                product teams in Redmond and Silicon Valley, and Microsoft's global
                UX Center in Texas, to define the consumer experience of Windows Live,
                Microsoft TV, and Microsoft Vista Widgets.
            </p>
            <ul className="uk-list uk-list-bullet">
                <li>Managed all user experience design, visual, and interaction design
                    for Microsoft’s Windows Live product launch.</li>
                <li>Managed user experience design and interaction design for Microsoft TV
                    and Microsoft Small Business views in Windows Live.com and overarching
                    integration with Microsoft Windows Vista desktop.</li>
                <li>Developed prototypes in JavaScript and Microsoft's widget architecture.</li>
                <li>Produced User Experience and UI Design guidelines and documentation.</li>
            </ul>
        </div>

        <div>
            <p className="uk-h4 uk-heading-line
                uk-text-uppercase uk-margin-remove-bottom">
                <span>Zyllem</span>
            </p>
            <small>Consultant</small>
            <p className={`${style.experienceCopyMeta} uk-article-meta`}>
                Start-up &bull; Consumer Research &bull; UI/UX Design
            </p>
            <p className={`${style.experienceCopyLead} uk-text-lead`}>
                Zyllem is a funded Singapore startup that is the leading
                provider of fast, cost-effective, crowdsourced delivery services and logistics in
                Southeast Asia. From workshops to user research, from UX to UI, I delivered a
                complete overhaul of the consumer experience for this startup's mobile app and
                web services.
            </p>
            <ul className="uk-list uk-list-bullet">
                <li>Led user experience and business strategy workshop for internal
                    leadership teams.</li>
                <li>Performed quantitative and qualitative user research, including surveys, data
                    analytics and path tracking, and user interviews. Collected and analysed data
                    to identify insights and develop a fresh engagement approach that would drive customer
                    acquisition through mobile app and website.</li>
                <li>Led user interface design including UX audits, wireframe production
                    and UI design.</li>
            </ul>
        </div>
    </article>

export default NotableProjects;
